import React, {Suspense, useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import CookieConsent from "react-cookie-consent";
//import theme from "../../styles/settings/variables_jsx.scss";

// const Cookies = React.lazy( () => import('js-cookie'));
//
// const CookieConsent = React.lazy( () => import('react-cookie-consent'));

const BannerCookies = () => {

    const isSSR = typeof window === "undefined"; // hack to ssr and lazy
    const [cookieTag, setCookieTag] = useState(Cookies.get('gdpr-google-tagmanager'));

    // function setProperties(){
    //     let vh = window.innerHeight * 0.01;
    //     document.documentElement.style.setProperty('--vh', `${vh}px`);
    // }
    //
    // useEffect(()=>{
    //     setProperties();
    //     window.addEventListener('resize', setProperties);
    //     return () => { window.removeEventListener('resize', setProperties) }
    // }, []);

    return(
        <>
            {!isSSR && (
                <Suspense fallback={null}>
                    { cookieTag && (
                        <>
                            <Helmet>
                                <script>
                                    {`
                                          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var
                                          f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                          })(window,document,'script','dataLayer','GTM-NDCSVV7')
                                    `}
                                </script>
                            </Helmet>
                            <noscript dangerouslySetInnerHTML={{
                                __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=>GTM-NDCSVV7" height="0" width="0"
                                    style="display:none;visibility:hidden"></iframe>
                                `,
                                }}
                            />
                        </>
                    )}
                    <CookieConsent
                        enableDeclineButton
                        onDecline = { ( )  =>  {
                            setCookieTag(false) ;
                        } }

                        onAccept = { ( ) => {
                            setCookieTag(true) ;
                        }

                        }

                        location="bottom"
                        buttonText="Aceptar todas"
                        declineButtonText="Rechazar"
                        style={{ background: "#26211D", minHeight: "29%", diplay:"flex", justifyContent:"flex-end", alignItems:"center", opacity:"0.9" }}
                        buttonStyle={{ color: "#fff", fontSize: "1rem", backgroundColor: "black", padding: "0.5em 1em" }}
                        declineButtonStyle={{color: "#999", fontSize: "1rem", backgroundColor:"#000", padding: "0.5em 1em" } }
                        cookieName="gdpr-google-tagmanager">
                        Este sitio web utiliza  <a href="#" target="_blank">cookies</a> propias y de terceros para recopilar información estadística sobre su navegación.
                    </CookieConsent>
                </Suspense>
            )}
        </>
    )
}
export default BannerCookies;