import ReactDOM from "react-dom";
import React from "react";
import BannerCookies from "./components/GTM/Banner";
import ComponentScrollReveal from "./components/Animtations/ScrollReveal";
import "./components/Forms/subscribe";
import Subscribe from "./components/Forms/subscribe";


const element = (
    <>
       <BannerCookies />
       <ComponentScrollReveal />
       <Subscribe />
    </>
);
ReactDOM.render(element, document.getElementById("root"));
