import React, {useLayoutEffect} from 'react';
import ScrollReveal from '../../js/lib/scrollreveal';



const ComponentScrollReveal = () => {

    let slideUp = {
        distance: '150%',
        origin: 'bottom',
        easing: 'ease-out',
        delay: 0,
        duration: 1500,
        opacity: 0
    };
    let slideLeft = {
        distance: '150%',
        origin: 'left',
        easing: 'ease-out',
        delay: 0,
        duration: 1500,
        opacity: 0
    };
    let slideRight = {
        distance: '150%',
        origin: 'right',
        easing: 'ease-out',
        delay: 0,
        duration: 1500,
        opacity: 0
    };
    let slideUpNumbers = {
        distance: '150%',
        origin: 'bottom',
        easing: 'ease-out',
        delay: 1000,
        duration: 2000,
        opacity: 0
    };
    let slideUpH2 = {
        distance: '150%',
        origin: 'bottom',
        easing: 'ease-out',
        delay: 0,
        duration: 1500,
        opacity: 0
    };
    let slideUpH3 = {
        distance: '150%',
        origin: 'bottom',
        easing: 'ease-out',
        delay: 500,
        duration: 1500,
        opacity: 0
    };
    let slideUpP = {
        distance: '150%',
        origin: 'bottom',
        easing: 'ease-out',
        delay: 1000,
        duration: 1500,
        opacity: 0
    };
    let diana = {
        distance: '0%',
        scale: 0,
        origin: 'bottom',
        easing: 'ease-out',
        delay: 1500,
        duration: 500,
        opacity: 0
    }
    let honda1 = {
        distance: '0%',
        scale: 0,
        origin: 'bottom',
        easing: 'ease-out',
        delay: 1000,
        duration: 1500,
        opacity: 0
    }
    let honda2 = {
        distance: '0%',
        scale: 0,
        origin: 'bottom',
        easing: 'ease-out',
        delay: 1000,
        duration: 2000,
        opacity: 0
    }
    let honda3 = {
        distance: '0%',
        scale: 0,
        origin: 'bottom',
        easing: 'ease-out',
        delay: 1000,
        duration: 3000,
        opacity: 0
    }






    useLayoutEffect(()=> {
        const numbers = document.querySelectorAll('.enums__number');
        const h2 = document.querySelectorAll('.enums__h2');
        const h3 = document.querySelectorAll('.enums__h3');
        const p = document.querySelectorAll('.enums__p');
        const sumbit = document.querySelectorAll("submit");

        const nodeArrayLeft = [
            document.querySelector('.caracteristica--1'),
            document.querySelector('.caracteristica--3'),
        ]

        const nodeArrayRight = [
            document.querySelector('.caracteristica--2'),
            document.querySelector('.caracteristica--4'),
        ]


        console.log("lgooo")
        // ScrollReveal().reveal('.container',  slideUp);
        ScrollReveal().reveal(numbers,  slideUpNumbers);
        ScrollReveal().reveal(h2,  slideUpH2);
        ScrollReveal().reveal(h3,  slideUpH3);
        ScrollReveal().reveal(p,  slideUpP);
        ScrollReveal().reveal(sumbit,  slideUpP);
        ScrollReveal().reveal('#subscribe',  slideUp);
        ScrollReveal().reveal('.o-calidad__imagen',  slideLeft);
        ScrollReveal().reveal('.diana',  diana);
        ScrollReveal().reveal('.honda1',  honda1);
        ScrollReveal().reveal('.honda2',  honda2);
        ScrollReveal().reveal('.honda3',  honda3);
        ScrollReveal().reveal(nodeArrayLeft, slideLeft);
        ScrollReveal().reveal(nodeArrayRight, slideRight);
        ScrollReveal().reveal('.o-contact__map', slideRight);

    }, []);

    return(
        <>
        </>
    )
}
export default ComponentScrollReveal;