import React, {Suspense, useEffect, useState} from 'react';

const Subscribe = () => {
    console.log("hola subscribe");
    const subscribe =  document.getElementById("subscribe");
    const data = new FormData(subscribe);
    subscribe.addEventListener("submit", (ev) => {
        // ev.preventDefault();
        // fetch("http://localhost/#blockEmailSubscription", {
        //     method: 'POST',
        //     body: JSON.stringify(data)
        // }).then(function(response) {
        //     if(response.ok) {
        //         console.log("ok")
        //     } else {
        //         throw "Error en la llamada Ajax";
        //     }
        //
        // })
        //     .then(function(texto) {
        //         console.log("then 2");
        //     })
        //     .catch(function(err) {
        //         console.log(err);
        //     });
    })
    return(
        <>

        </>
    )
}
export default Subscribe;